<template>
  <v-skeleton-loader
    v-if="skeleton"
    type="article, actions"
  ></v-skeleton-loader>
  <div v-else>
    <v-card
      :key="data.id"
    >
      <v-card-title class="container-image">
        <span>Descrição do veiculo</span>

        <v-img
          v-show="data.photo"
          class="image-content"
          :src="data.photo"
        >
        </v-img>
      </v-card-title>

      <v-card-text>
        <v-text-field
          v-model="data.name"
          :append-icon="icons.mdiPencilOutline"
          label="Nome"
          outlined
          dense
        ></v-text-field>
        <v-text-field
          v-model="data.model"
          :append-icon="icons.mdiPencilOutline"
          label="Modelo"
          outlined
          dense
        ></v-text-field>
        <v-text-field
          v-model="data.brand"
          :append-icon="icons.mdiPencilOutline"
          label="Marca"
          outlined
          dense
        ></v-text-field>
        <v-text-field
          v-model="data.price"
          :append-icon="icons.mdiPencilOutline"
          label="Valor"
          outlined
          dense
        ></v-text-field>
        <v-text-field
          v-model="documentValue"
          :append-icon="icons.mdiPencilOutline"
          label="Documento %"
          outlined
          dense
        ></v-text-field>

        <v-file-input
          v-model="vehicleImage"
          label="Alterar Imagem do Veículo"
          :append-icon="icons.mdiCamera"
          :prepend-icon="null"
          accept="image/*"
          type="file"
          outlined
          chips
          dense
        ></v-file-input>

        <div class="d-flex justify-end mt-5">
          <v-btn
            color="info"
            @click="sendModalValue"
          >
            <span
              v-if="!loadingCircleSendForm"
            >Enviar</span>
            <v-progress-circular
              v-else
              color="white"
              indeterminate
            >
            </v-progress-circular>
          </v-btn>
          <v-btn
            color="error"
            class="ml-5"
            @click="closeModal"
          >
            Cancelar
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import axiosIns from '@/plugins/axios'
import { mdiCamera, mdiPencilOutline } from '@mdi/js'

export default {
  components: {

  },

  props: {
    data: {
      type: Object,
      required: true,
    },

    updatedTable: {
      type: Function,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {
      skeleton: false,
      loadingCircleSendForm: false,
      imageLoaded: false,
      vehicleImage: [],
      documentValue: '',

      icons: {
        mdiPencilOutline,
        mdiCamera,
      },
    }
  },

  computed: {

  },

  created() {
  },

  methods: {
    async getDataById() {
      this.skeleton = true
      await axiosIns.get(`/api/app/v1/vehicle/edit/${this.data.id}`)
        .then(
          resp => {
            this.dataDetails = resp.data.data
            this.skeleton = false
          },
        )
    },

    async sendModalValue() {
      this.loadingCircleSendForm = true

      const body = {
        name: this.data.name,
        model: this.data.model,
        brand: this.data.brand,
        document: this.data.document,
        price: this.removeSpecialCharacters(this.data.price),
      }

      await axiosIns.put(`/api/app/v1/vehicle/update/${this.data.id}`, body)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Cadastro Alterado!',
            showConfirmButton: false,
            timer: 3000,

          })
          this.loadingCircleSendForm = false
          this.updatedTable()
          this.sendPhotoVehicle()
        })
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: 'Erro ao enviar dados!',
            showConfirmButton: false,
            text: error,
            timer: 3000,
          })
        })

      this.closeModal()
    },

    async sendPhotoVehicle() {
      const formData = new FormData()
      const vehicleImageToArray = []

      vehicleImageToArray.push(this.vehicleImage)
      vehicleImageToArray.forEach(file => {
        formData.append('files[]', file)
      })

      await axiosIns.post(`/api/app/v1/vehicle/save_photo/${this.data.id}`, formData)
        .then(() => {
          this.inputName = ''
          this.inputValuePrice = ''
          this.inputModel = ''
          this.inputBrand = ''
          this.vehicleImage = []
        })

      this.updatePage()
      this.closeModal()
    },

    removeSpecialCharacters(item) {
      item.replace(/[^a-zA-Z0-9 ]/g, '')

      return Number(item)
    },

    closeModal() {
      this.vehicleImage = []
      this.$emit('close')
    },

  },

}
</script>
<style scoped>
.container-image {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.image-content{
  margin-top: 30px;
  height: 200px;
  width: 300px;
  border-radius: 8px;
}
</style>
