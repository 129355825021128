<template>
  <v-card>
    <v-card-title class="justify-center">
      Cadastrar novo veículo
    </v-card-title>
    <v-card-text>
      <v-text-field
        v-model="inputName"
        label="Nome"
        dense
        outlined
        class="mt-2"
        :rules="[rules.required]"
      >
      </v-text-field>
      <v-text-field
        v-model="inputModel"
        label="Modelo"
        dense
        outlined
        :rules="[rules.required]"
      >
      </v-text-field>
      <v-text-field
        v-model="inputBrand"
        label="Marca"
        dense
        outlined
        :rules="[rules.required]"
      >
      </v-text-field>

      <v-text-field
        v-model="inputValuePrice"
        label="Valor"
        dense
        outlined
        type="number"
        prefix="$"
        :rules="[rules.required]"
      ></v-text-field>

      <v-text-field
        v-model="inputValueDocument"
        label="Taxa Documento"
        dense
        outlined
        type="number"
        prefix="%"
        :rules="[rules.required]"
      ></v-text-field>

      <v-file-input
        v-model="vehicleImage"
        label="Anexar Imagens do Veículo"
        :prepend-inner-icon="icons.mdiCamera"
        :prepend-icon="null"
        accept="image/*"
        type="file"
        outlined
        chips
        dense
      ></v-file-input>

      <div class="d-flex justify-end mt-5">
        <v-btn
          color="info"
          @click="sendModalValue"
        >
          <span
            v-if="!loadingCircleSendForm"
          >Enviar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
        <v-btn
          color="error"
          class="ml-5"
          @click="closeModal"
        >
          Cancelar
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import { mdiCamera } from '@mdi/js'

export default {

  props: {
    updatedTable: {
      type: Function,
      required: true,
      default: () => {},
    },

  },

  data() {
    return {

      inputName: '',
      inputValuePrice: '',
      inputValueDocument: '',
      inputModel: '',
      inputBrand: '',
      vehicleImage: [],
      dataIdVehicle: '',
      containsVehicleImage: false,
      loadingCircleSendForm: false,

      icons: {
        mdiCamera,
      },

      rules: {
        required: value => !!value || 'Campo obrigatório.',

      },
    }
  },

  watch: {
    vehicleImage() {
      if (this.vehicleImage) {
        this.containsVehicleImage = true

        return this.containsVehicleImage
      }
      this.containsVehicleImage = false

      return this.containsVehicleImage
    },

    immediate: true,
  },

  methods: {
    removeSpecialCharacters(item) {
      item.replace(/[^a-zA-Z0-9 ]/g, '')

      return Number(item)
    },

    async sendPhotoVehicle() {
      const formData = new FormData()
      const vehicleImageToArray = []

      vehicleImageToArray.push(this.vehicleImage)
      vehicleImageToArray.forEach(file => {
        formData.append('files[]', file)
      })

      await axiosIns.post(`/api/app/v1/vehicle/save_photo/${this.dataIdVehicle}`, formData)
        .then(() => {
          this.inputName = ''
          this.inputValuePrice = ''
          this.inputModel = ''
          this.inputBrand = ''
          this.vehicleImage = []
        })

      this.closeModal()
    },

    async sendModalValue() {
      this.loadingCircleSendForm = true

      if (this.containsVehicleImage) {
        const body = {
          name: this.inputName,
          model: this.inputModel,
          brand: this.inputBrand,
          document: this.inputValueDocument,
          price: this.removeSpecialCharacters(this.inputValuePrice),
        }

        await axiosIns.post('/api/app/v1/vehicle/store', body)
          .then(res => {
            this.$swal({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Sua resposta enviada com sucesso!',
              showConfirmButton: false,
              timer: 3000,

            })
            this.dataIdVehicle = res.data.data.id
            this.loadingCircleSendForm = false
            this.updatedTable()
          })
          .catch(error => {
            this.$swal({
              icon: 'error',
              title: 'Erro ao enviar dados!',
              showConfirmButton: false,
              text: error,
              timer: 3000,
            })
          })

        this.sendPhotoVehicle()
      } else {
        this.loadingCircleSendForm = false
        this.$swal({
          icon: 'error',
          title: 'O campo imagem não pode ficar vazio!',
          showConfirmButton: false,
          text: 'Tente novamente',
          timer: 3000,
        })
      }
    },

    closeModal() {
      this.$emit('close')
    },

  },
}
</script>
  <style>
    /* Retira o incrementador padrão dos inputs do tipo "number"*/
    input[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    input[type=number] {
      -moz-appearance: textfield;
      appearance: textfield;
    }
    </style>
