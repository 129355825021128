import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v(" Veículos Cadastrados ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"info"},on:{"click":_vm.openModalVehicle}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlaylistPlus)+" ")]),_vm._v(" Novo ")],1)],1),_c(VDataTable,{key:_vm.itemsTable.id,attrs:{"headers":_vm.headers,"items":_vm.itemsTable,"loading":_vm.loading,"loading-text":"Carregando dados..."},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.price).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.created_at))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"me-2",attrs:{"medium":""},on:{"click":function($event){return _vm.openVehicleDetails(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])]}}],null,true)}),_c(VDialog,{attrs:{"width":"700px"},model:{value:(_vm.showModalVehicle),callback:function ($$v) {_vm.showModalVehicle=$$v},expression:"showModalVehicle"}},[_c('ModalVehicle',{attrs:{"updated-table":_vm.updatedTable},on:{"close":function($event){_vm.showModalVehicle = false}}})],1),_c(VDialog,{attrs:{"width":"700px"},model:{value:(_vm.showVehicleDetails),callback:function ($$v) {_vm.showVehicleDetails=$$v},expression:"showVehicleDetails"}},[_c('VehicleDetails',{key:_vm.dataDetails.id,attrs:{"data":_vm.dataDetails,"updated-table":_vm.updatedTable},on:{"close":function($event){_vm.showVehicleDetails = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }