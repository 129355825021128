<template>
  <v-card>
    <v-card-title class="d-flex justify-content-between">
      <span>
        Veículos Cadastrados
      </span>
      <v-spacer></v-spacer>
      <v-btn
        color="info"
        @click="openModalVehicle"
      >
        <v-icon
          size="30"
          class="me-2"
        >
          {{ icons.mdiPlaylistPlus }}
        </v-icon>
        Novo
      </v-btn>
    </v-card-title>
    <v-data-table
      :key="itemsTable.id"
      :headers="headers"
      :items="itemsTable"
      :loading="loading"
      loading-text="Carregando dados..."
    >
      <template #[`item.price`]="{ item }">
        {{ Number(item.price).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}) }}
      </template>

      <template #[`item.created_at`]="{ item }">
        {{ dateFormat(item.created_at) }}
      </template>

      <template #[`item.action`]="{item}">
        <v-icon
          medium
          class="me-2"
          @click="openVehicleDetails(item)"
        >
          {{ icons.mdiPencilOutline }}
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog
      v-model="showModalVehicle"
      width="700px"
    >
      <ModalVehicle
        :updated-table="updatedTable"
        @close="showModalVehicle = false"
      ></ModalVehicle>
    </v-dialog>

    <v-dialog
      v-model="showVehicleDetails"
      width="700px"
    >
      <VehicleDetails
        :key="dataDetails.id"
        :data="dataDetails"
        :updated-table="updatedTable"
        @close="showVehicleDetails = false"
      ></VehicleDetails>
    </v-dialog>
  </v-card>
</template>
<script>

import axiosIns from '@/plugins/axios'
import { mdiPencilOutline, mdiPlaylistPlus } from '@mdi/js'
import ModalVehicle from './ModalVehicle.vue'
import VehicleDetails from './VehicleDetails.vue'

export default {

  components: {
    ModalVehicle,
    VehicleDetails,
  },
  data() {
    return {

      headers: [
        {
          text: 'NOME', value: 'name', sortable: false, align: '',
        },
        {
          text: 'MODELO', value: 'model', sortable: false, align: 'center',
        },
        {
          text: 'MARCA', value: 'brand', sortable: false, align: 'center',
        },
        {
          text: 'VALOR', value: 'price', sortable: false, align: 'center',
        },
        {
          text: 'CADASTRADO', value: 'created_at', sortable: false, align: 'center',
        },
        {
          text: 'AÇÃO', value: 'action', sortable: false, align: 'center',
        },
      ],

      itemsTable: [],
      loading: false,
      showModalVehicle: false,
      showVehicleDetails: false,
      dataDetails: {},

      icons: {
        mdiPlaylistPlus,
        mdiPencilOutline,
      },
    }
  },

  created() {
    this.getItemsTable()
  },

  methods: {

    async getItemsTable() {
      this.loading = true

      await axiosIns.get('/api/app/v1/vehicle/index').then(
        res => {
          this.itemsTable = res.data.data
          this.loading = false
        },

      )
    },

    updatedTable() {
      this.getItemsTable()
    },

    openModalVehicle() {
      this.showModalVehicle = true
    },

    openVehicleDetails(item) {
      this.showVehicleDetails = true
      this.dataDetails = item
    },

    dateFormat(date) {
      const dateSliced = date.slice(0, 10)

      const hour = date.slice(11, 19)

      const dateDivided = dateSliced.split('-')

      const dateFormated = `${dateDivided[2]}/${dateDivided[1]}/${dateDivided[0]} - ${hour}`

      return dateFormated
    },

    closeModal() {
      this.showModalVehicle = false
      this.showVehicleDetails = false
      this.dataDetails = ''
    },
  },
}

</script>
