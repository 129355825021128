import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"justify-center"},[_vm._v(" Cadastrar novo veículo ")]),_c(VCardText,[_c(VTextField,{staticClass:"mt-2",attrs:{"label":"Nome","dense":"","outlined":"","rules":[_vm.rules.required]},model:{value:(_vm.inputName),callback:function ($$v) {_vm.inputName=$$v},expression:"inputName"}}),_c(VTextField,{attrs:{"label":"Modelo","dense":"","outlined":"","rules":[_vm.rules.required]},model:{value:(_vm.inputModel),callback:function ($$v) {_vm.inputModel=$$v},expression:"inputModel"}}),_c(VTextField,{attrs:{"label":"Marca","dense":"","outlined":"","rules":[_vm.rules.required]},model:{value:(_vm.inputBrand),callback:function ($$v) {_vm.inputBrand=$$v},expression:"inputBrand"}}),_c(VTextField,{attrs:{"label":"Valor","dense":"","outlined":"","type":"number","prefix":"$","rules":[_vm.rules.required]},model:{value:(_vm.inputValuePrice),callback:function ($$v) {_vm.inputValuePrice=$$v},expression:"inputValuePrice"}}),_c(VTextField,{attrs:{"label":"Taxa Documento","dense":"","outlined":"","type":"number","prefix":"%","rules":[_vm.rules.required]},model:{value:(_vm.inputValueDocument),callback:function ($$v) {_vm.inputValueDocument=$$v},expression:"inputValueDocument"}}),_c(VFileInput,{attrs:{"label":"Anexar Imagens do Veículo","prepend-inner-icon":_vm.icons.mdiCamera,"prepend-icon":null,"accept":"image/*","type":"file","outlined":"","chips":"","dense":""},model:{value:(_vm.vehicleImage),callback:function ($$v) {_vm.vehicleImage=$$v},expression:"vehicleImage"}}),_c('div',{staticClass:"d-flex justify-end mt-5"},[_c(VBtn,{attrs:{"color":"info"},on:{"click":_vm.sendModalValue}},[(!_vm.loadingCircleSendForm)?_c('span',[_vm._v("Enviar")]):_c(VProgressCircular,{attrs:{"color":"white","indeterminate":""}})],1),_c(VBtn,{staticClass:"ml-5",attrs:{"color":"error"},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }